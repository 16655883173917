import * as React from "react"
import { navigate } from "gatsby"

import { isLoggedIn } from "../../utils/auth"

interface PrivateRouteProps {
  component: React.ReactNode,
  location: object,
  rest: object
}

const PrivateRoute = ({ component: Component, location, ...rest }: PrivaetRouteProps) => {
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/app/login`)
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
