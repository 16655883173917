import * as React from "react"
import { PageProps } from "gatsby"
import { Router } from "@reach/router"

import Layout from "../components/Layout"
import Profile from "../components/Profile"
import Details from "../components/Details"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"

const App = ({ location }: PageProps) => (
  <Layout location={location}>
    <Status />
    <Router basepath="/app">
      <PrivateRoute path="/details" component={Details} location={location} />
      <PrivateRoute path="/profile" component={Profile} location={location} />
      <Login path="/login" />
    </Router>
  </Layout>
)

export default App
