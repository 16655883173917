import React from "react"
import PropTypes from "prop-types"
import styles from "./view.module.css"

interface ViewProps {
  title: string,
  children: object
}

const View = ({ title, children }: ViewProps) => (
  <section className={styles.view}>
    <h1 className={styles[`view__heading`]}>{title}</h1>
    {children}
  </section>
)

export default View
